exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cement-crusher-manufacturers-js": () => import("./../../../src/pages/cement-crusher-manufacturers.js" /* webpackChunkName: "component---src-pages-cement-crusher-manufacturers-js" */),
  "component---src-pages-cement-grinding-packing-js": () => import("./../../../src/pages/cement-grinding-packing.js" /* webpackChunkName: "component---src-pages-cement-grinding-packing-js" */),
  "component---src-pages-cement-plant-spare-parts-js": () => import("./../../../src/pages/cement-plant-spare-parts.js" /* webpackChunkName: "component---src-pages-cement-plant-spare-parts-js" */),
  "component---src-pages-cement-plant-upgrading-and-modernization-solution-js": () => import("./../../../src/pages/cement-plant-upgrading-and-modernization-solution.js" /* webpackChunkName: "component---src-pages-cement-plant-upgrading-and-modernization-solution-js" */),
  "component---src-pages-cement-storage-system-manufacturers-js": () => import("./../../../src/pages/cement-storage-system-manufacturers.js" /* webpackChunkName: "component---src-pages-cement-storage-system-manufacturers-js" */),
  "component---src-pages-clinker-production-manufacturers-js": () => import("./../../../src/pages/clinker-production-manufacturers.js" /* webpackChunkName: "component---src-pages-clinker-production-manufacturers-js" */),
  "component---src-pages-cone-crusher-manufacturers-js": () => import("./../../../src/pages/cone-crusher-manufacturers.js" /* webpackChunkName: "component---src-pages-cone-crusher-manufacturers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-customize-cement-processing-plant-manufacturers-js": () => import("./../../../src/pages/customize-cement-processing-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-customize-cement-processing-plant-manufacturers-js" */),
  "component---src-pages-disc-pelletizer-manufacturers-js": () => import("./../../../src/pages/disc-pelletizer-manufacturers.js" /* webpackChunkName: "component---src-pages-disc-pelletizer-manufacturers-js" */),
  "component---src-pages-ggbs-dryering-solution-js": () => import("./../../../src/pages/ggbs-dryering-solution.js" /* webpackChunkName: "component---src-pages-ggbs-dryering-solution-js" */),
  "component---src-pages-ggbs-grinding-solution-js": () => import("./../../../src/pages/ggbs-grinding-solution.js" /* webpackChunkName: "component---src-pages-ggbs-grinding-solution-js" */),
  "component---src-pages-gyratory-crusher-manufacturers-js": () => import("./../../../src/pages/gyratory-crusher-manufacturers.js" /* webpackChunkName: "component---src-pages-gyratory-crusher-manufacturers-js" */),
  "component---src-pages-hammer-crusher-manufacturers-js": () => import("./../../../src/pages/hammer-crusher-manufacturers.js" /* webpackChunkName: "component---src-pages-hammer-crusher-manufacturers-js" */),
  "component---src-pages-impact-crusher-manufacturers-js": () => import("./../../../src/pages/impact-crusher-manufacturers.js" /* webpackChunkName: "component---src-pages-impact-crusher-manufacturers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jawcrusher-manufacturers-js": () => import("./../../../src/pages/jawcrusher-manufacturers.js" /* webpackChunkName: "component---src-pages-jawcrusher-manufacturers-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-blog-how-cement-made-in-factory-index-mdx": () => import("./../../../src/pages/{Mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/blog/how-cement-made-in-factory/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-blog-how-cement-made-in-factory-index-mdx" */),
  "component---src-pages-mini-cement-plant-manufacturers-js": () => import("./../../../src/pages/mini-cement-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-mini-cement-plant-manufacturers-js" */),
  "component---src-pages-project-consultancy-1-js": () => import("./../../../src/pages/project-consultancy-1.js" /* webpackChunkName: "component---src-pages-project-consultancy-1-js" */),
  "component---src-pages-project-consultancy-js": () => import("./../../../src/pages/project-consultancy.js" /* webpackChunkName: "component---src-pages-project-consultancy-js" */),
  "component---src-pages-raw-mill-manufacturers-js": () => import("./../../../src/pages/raw-mill-manufacturers.js" /* webpackChunkName: "component---src-pages-raw-mill-manufacturers-js" */),
  "component---src-pages-rotary-kiln-cement-plant-manufacturers-js": () => import("./../../../src/pages/rotary-kiln-cement-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-rotary-kiln-cement-plant-manufacturers-js" */),
  "component---src-pages-stacker-reclaimer-manufacturers-js": () => import("./../../../src/pages/stacker-reclaimer-manufacturers.js" /* webpackChunkName: "component---src-pages-stacker-reclaimer-manufacturers-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-turnkey-cement-plants-manufacturers-js": () => import("./../../../src/pages/turnkey-cement-plants-manufacturers.js" /* webpackChunkName: "component---src-pages-turnkey-cement-plants-manufacturers-js" */),
  "component---src-pages-vertical-lime-kiln-manufacturers-js": () => import("./../../../src/pages/vertical-lime-kiln-manufacturers.js" /* webpackChunkName: "component---src-pages-vertical-lime-kiln-manufacturers-js" */),
  "component---src-pages-vsk-cement-plant-manufacturers-js": () => import("./../../../src/pages/vsk-cement-plant-manufacturers.js" /* webpackChunkName: "component---src-pages-vsk-cement-plant-manufacturers-js" */),
  "component---src-pages-warehouse-godown-js": () => import("./../../../src/pages/warehouse-godown.js" /* webpackChunkName: "component---src-pages-warehouse-godown-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */)
}

