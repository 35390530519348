// Bootstrap Link
import "././node_modules/bootstrap/dist/css/bootstrap.min.css"
import "././node_modules/bootstrap/dist/js/bootstrap.bundle"
import "././node_modules/bootstrap/dist/css/bootstrap.css"
import 'bootstrap-icons/font/bootstrap-icons.css';
// Style Sheets
import './src/styles/style.css';
import './src/styles/style.scss';
import './src/styles/responsive.scss';

// export const onRouteUpdate = ({ location, prevLocation }) => {
//     window.scrollTo(0, 0);
// };
import ReactDOM from "react-dom/client"

export const onRouteUpdate = () => {
    window.scrollTo(0, 0);
};

export const replaceHydrateFunction = () => {

    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}